<template>
  <div
    class="dropdown"
    :class="{ 'dropdown--disabled': disabled }"
    v-click-outside="hide"
  >
    <div
      class="dropdown__select"
      :class="{ 'dropdown__select--opened': opened, invalid: invalid }"
      @click="toggle()"
    >
      <div class="dropdown__value">
        <slot v-if="!value" name="selectLabel">{{
          $t('forms.placeholders.dropdown')
        }}</slot>
        <span>{{ selected() }}</span>
      </div>
    </div>
    <ul v-if="opened" class="dropdown__options">
      <slot></slot>
      <li v-if="empty" class="dropdown__empty">
        <slot name="emptyLabel"> No options </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Dropdown',
  directives: {
    ClickOutside,
  },
  provide() {
    return { dropdown: this };
  },
  model: {
    prop: 'value',
    event: 'value-change',
  },
  data: () => ({
    opened: false,
  }),
  props: {
    value: [Object, String, Number, Boolean, Array],
    disabled: Boolean,
    invalid: Boolean,
  },
  methods: {
    select(value) {
      if (this.disabled) return;
      this.$emit('value-change', value);
      this.opened = false;
    },
    selected() {
      const options = this.$slots['default'];
      if (!options) return null;
      const selected = options.find(
        (x) => x.componentOptions.propsData.value === this.value
      );
      return selected ? selected.componentOptions.propsData.label : null;
    },
    hide() {
      if (this.opened) {
        this.opened = false;
        this.$emit('blur');
      }
    },

    open() {
      if (!this.opened && !this.disabled) {
        this.opened = true;
        this.$emit('focus');
      }
    },

    toggle() {
      if (this.opened) {
        this.hide();
      } else {
        this.open();
      }
    },
  },
  computed: {
    empty() {
      if (!this.$slots['default']) return true;
      return this.$slots['default'].length === 0;
    },
  },
};
</script>

<style scoped></style>
