<template>
  <li
    class="dropdown__option"
    :class="{ 'dropdown__option--selected': selected }"
    @click="select"
  >
    {{ label }}
  </li>
</template>

<script>
export default {
  name: 'DropdownOption',
  inject: ['dropdown'],
  props: {
    value: {
      type: [Object, String, Number, Boolean, Array],
      required: true,
    },
    label: String,
  },
  methods: {
    select() {
      this.dropdown.select(this.value, this.label);
    },
  },
  computed: {
    selected() {
      return this.value === this.dropdown.value;
    },
  },
};
</script>

<style scoped></style>
