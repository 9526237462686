























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import TestLocator from '@/core/test-utils/test-locator';

@Component({ directives: { TestLocator } })
export default class FileView extends Vue {
  @Prop({ required: true })
  file!: File;

  @Prop({ type: Boolean })
  disabled!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({
    type: [Number, Object],
    default: null,
    validator(value: number | null): boolean {
      if (!value) return true;
      return value <= 1;
    },
  })
  progress!: number | null;

  @Emit('remove')
  onRemove() {
    return this.file;
  }

  get sizeMb() {
    const bytes = this.file.size;
    return Math.round((bytes / Math.pow(1024, 2)) * 100) / 100;
  }

  get fileName() {
    const nameTokens = this.file.name.split('.');
    const ext = nameTokens.pop();
    const name = nameTokens.join('.');
    if (name.length <= 12) {
      return this.file.name;
    } else {
      return `${name.slice(0, 10)}...${name.slice(-2)}.${ext}`;
    }
  }
}
