











import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class AttachInput extends Vue {
  $refs!: {
    input: HTMLInputElement;
  };

  @Prop({ type: Boolean })
  multiple!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  handle(event: any) {
    this.onChange([...event.target.files]);
    this.onSelect(event);
    this.$refs.input.value = '';
  }

  @Emit('change')
  onChange(files: File[]) {
    return files;
  }

  @Emit('select')
  onSelect(evt: any) {
    return evt;
  }
}
