















































































































































































































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ControlLabel from '@/themes/v1/components/ControlLabel.vue';
import { Dropdown, DropdownOption } from '@/themes/v1/components/Dropdown';
import ControlError from '@/themes/v1/components/ControlError.vue';
import MessageBox from '@/themes/v1/components/MessageBox.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BoxPage from '@themes/v1/components/BoxPage.vue';
import AttachInput from '@themes/v1/components/AttachInput.vue';
import InlineCtrlWithLabel from '@themes/v1/components/InlineCtrlWithLabel.vue';
import Checkbox from '@themes/v1/components/Checkbox.vue';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';
import config from '@/env';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '../../../stores/player.store';
import { TicketCreatorOptions } from '@/modules/sts/creator/creator-options.model';
import {
  CreateTicketCommand,
  CreatingTicketResponseStatus,
} from '@/modules/sts/creator/create-ticket.model';
import FileView from '@/themes/v1/components/FileView.vue';
import { SsrCtx, WithAsyncData, WithFetch, WithMeta } from '@/core/vue.types';
import {
  pushWithLangAndPlatform,
  universalRedirect,
} from '@/themes/v1/fns/router';
import { AppStore } from '@/themes/v1/stores/app.store';
import { privacyAndCookies } from '@/modules/sts/sts.links';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { ChatStore } from '@/themes/v1/stores/chat.store';
import { validateFile } from '@/themes/v1/views/sts/validators';
import { TicketsStore } from '@/themes/v1/stores/tickets.store';
import Spinner from '@/themes/v1/components/Spinner.vue';
import { MetaInfo } from 'vue-meta';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    Spinner,
    FileView,
    Checkbox,
    InlineCtrlWithLabel,
    AttachInput,
    BoxPage,
    MessageBox,
    ControlError,
    Dropdown,
    DropdownOption,
    ControlLabel,
    BoxContent,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    TestLocator,
  },
})
export default class Create
  extends Vue
  implements WithAsyncData, WithFetch, WithMeta {
  @Inject()
  ticketCreatorService!: TicketCreatorService;

  async asyncData(ctx: SsrCtx) {
    const ticketCreatorService = resolveFromCtx<TicketCreatorService>(
      'ticketCreatorService',
      ctx
    );
    const options = await ticketCreatorService.getOptions(ctx.app.$i18n.locale);
    const ticketsStore = getModule(TicketsStore, ctx.store);
    ticketsStore.setOptions({ lang: ctx.app.$i18n.locale, options });
    return {
      options,
    };
  }

  async fetch(ctx: SsrCtx): Promise<void> {
    const playerStore = getModule(PlayerStore, ctx.store);
    if (!playerStore.platformType) {
      universalRedirect(ctx, 'problems');
    }
  }

  playerStore = getModule(PlayerStore, this.$store);
  appStore = getModule(AppStore, this.$store);

  form: any = {
    message: '',
    language: '',
    email: '',
    playerName: this.playerStore.playerName || '',
    files: [] as File[],
    reason: null,
    issue: null,
    tosAgreement: true,
  };

  options: TicketCreatorOptions = {
    languages: [],
    platforms: [],
    reasons: [],
    languagesWithReasonDetector: [],
  };

  processing = false;
  private showFileError = false;
  private showFileSizeError = false;

  get platform() {
    return this.playerStore.platformType || 'android';
  }
  get reasons() {
    return this.options.reasons
      .filter((r) => r.platforms.includes(this.platform))
      .map((r) => ({
        ...r,
        subtypes: r.subtypes.filter((s) => s.platforms.includes(this.platform)),
      }));
  }
  get issues() {
    return (this.form.reason && this.form.reason.subtypes) || [];
  }
  get notification() {
    if (this.form.issue != null) {
      return this.form.issue.notification;
    }
    if (this.form.reason != null && this.issues.length === 0) {
      return this.form.reason.notification;
    }
    return null;
  }

  @Watch('form.reason')
  watchFormReason() {
    if (this.form.issue != null) {
      this.form.issue = null;
    }
  }

  async mounted() {
    const playerLanguage =
      this.playerStore.gameLocale ||
      this.playerStore.deviceLocale ||
      this.$i18n.locale;
    if (this.options.languages.map((x) => x.tag).includes(playerLanguage)) {
      this.form.language = playerLanguage;
    } else {
      this.form.language = 'en';
    }
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.createTicket.title') as string,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('meta.createTicket.description') as string,
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$t('meta.createTicket.keywords') as string,
        },
      ],
    };
  }

  private async addFile(files: File[]) {
    let hasError = false;
    files.forEach((file) => {
      const valid = validateFile(file);
      if (valid) {
        if (this.currentFileSize + file.size > config.maxAttachment) {
          this.showFileSizeError = true;
        } else if (!this.hasFile(file)) {
          this.form.files = [...this.form.files, file];
          this.showFileSizeError = false;
        }
      } else {
        hasError = true;
      }
    });

    this.showFileError = hasError;
  }

  private hasFile(file: File) {
    return this.form.files.find(
      (x: File) =>
        x.name === file.name &&
        x.size === file.size &&
        x.lastModified === file.lastModified
    );
  }

  get currentFileSize() {
    return this.form.files.reduce((sum, x) => x.size + sum, 0);
  }

  private removeFile(file: File) {
    this.showFileError = false;
    this.showFileSizeError = false;
    this.form.files = this.form.files.filter((f) => f !== file);
  }

  async submit() {
    if (this.form.reason) {
      this.$track(
        'ask_question',
        'click_button_send_question',
        this.form.reason.tag
      );
    }

    const command: CreateTicketCommand = {
      ...this.form,
      game: config.ticketsGame,
      reason: this.form.reason && this.form.reason.tag,
      issue: this.form.issue && this.form.issue.tag,
      playerId: this.playerStore.playerId,
      platformType: this.playerStore.platformType,
      loginId: this.playerStore.loginId,
      advId: this.playerStore.advId,
      gameVersion: this.playerStore.gameVersion,
      deviceModel: this.playerStore.deviceModel,
      gameLocale: this.playerStore.gameLocale,
      deviceLocale: this.playerStore.deviceLocale,
      utcOffset: this.playerStore.utcOffset,
    };
    this.processing = true;
    try {
      const result = await this.ticketCreatorService.create(command);
      if (this.playerStore.isAuth) {
        if (result.status === CreatingTicketResponseStatus.Created) {
          await pushWithLangAndPlatform(
            this.$router,
            `tickets/${result.ticketNumber}`
          );
        } else if (result.status === CreatingTicketResponseStatus.Duplicate) {
          const chatStore = getModule(ChatStore, this.$store);
          chatStore.setNotification({
            title: this.$t('phrases.warning') as string,
            text: this.$t('pages.tickets.duplicate-error') as string,
          });
          await pushWithLangAndPlatform(this.$router, `tickets`);
        } else if (result.status === CreatingTicketResponseStatus.InProcess) {
          await pushWithLangAndPlatform(this.$router, `tickets/waiting`);
        } else {
          await pushWithLangAndPlatform(this.$router, 'tickets/failed');
        }
      } else {
        if (result.status === CreatingTicketResponseStatus.Duplicate) {
          await pushWithLangAndPlatform(this.$router, 'tickets/failed');
          return;
        }
        await pushWithLangAndPlatform(this.$router, 'tickets/success');
      }
    } catch (e) {
      await pushWithLangAndPlatform(this.$router, 'tickets/failed');
    } finally {
      this.processing = false;
    }
  }

  get privacyAndCookies() {
    return privacyAndCookies[this.$i18n.locale];
  }
}
