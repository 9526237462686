export const privacyAndCookies = {
  en: 'https://plarium.com/en/privacy-and-cookie-policy/',
  ru: 'https://plarium.com/ru/konfidentsialnost-i-ispolzovaniye-faylov-cookie/',
  fr: 'https://plarium.com/fr/politique-de-confidentialite-et-de-cookies/',
  es: 'https://plarium.com/es/politica-de-privacidad-y-de-cookies/',
  de: 'https://plarium.com/de/datenschutz-und-cookie-richtlinien/',
  it: 'https://plarium.com/it/disciplinare-sulla-privacy-e-uso-dei-cookie/',
  ko: 'https://plarium.com/ko/gaein-jeongbo-boho-mich-kuki-jeongchaeg/',
  ja: 'https://plarium.com/ja/puraibashi-to-kukkiporishi/',
  tr: 'https://plarium.com/tr/gizlilik-ve-cerez-nizamnamesi/',
};
